/*Avula, Manisha -1002118363
Avula, Sathwik Reddy -1002120527
Balay, Vaishnavi - 1002060510
Balay, Likhitha - 1002070513
Amanullah, Asadullah - 1002031972*/



body {
  font-family: Tahoma, sans-serif;
}

.header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
}

/*he header links */
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }

  .header-right {
    float: none;
  }
}

.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  width: 100%;
}

.heading1-div {
  padding: 20px;
  background-color: aliceblue;
  overflow: hidden;
}

.heading2-div {
  padding: 10px;
  background-color: azure;
  overflow: hidden;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  font-size: 18px;
  line-height: 1.5;
}

footer {
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f1f1f1;
  height: auto;
  width: 100vw;
}

.contact-us-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content h3 {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
}

.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
}

.footer-bottom {
  background: #f1f1f1;
  width: 100vw;
  padding: 20px;
  text-align: center;
}

.footer-bottom p {

  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
}

.footer-bottom p a {
  color: #44bae8;
  font-size: 16px;
  text-decoration: none;
}

.footer-bottom span {
  text-transform: uppercase;
  opacity: .4;
  font-weight: 200;
}

@media (max-width:500px) {
  .footer-menu ul {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.form {
  padding: 20px 12px 10px 20px;
  font: 13px Arial, Helvetica, sans-serif;
  width: 100%;
}

.form-heading {
  font-weight: bold;
  font: italic;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  font-size: 20px;
  padding-bottom: 3px;
}

.form label {
  font-size: 18px;
  display: block;
  margin: 0px 0px 15px 0px;
}

.form label>span {
  width: 100px;
  font-weight: bold;
  float: left;
  padding-top: 8px;
  padding-right: 5px;
}

.form input.input-field,
.form .select-field {
  width: 48%;
}

.form .textarea-field {
  height: 100px;
  width: 55%;
}

.form input[type=submit],
.form input[type=button] {
  border: none;
  padding: 8px 15px 8px 15px;
  background: #44bae8;
  color: #fff;
  width: 100%;
}

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}

table {
	width:100%;
  border: 1px solid;
}

tr td{
  border: 1px solid;
}

td form {
	margin:0;padding:0;
}
td form button.button{
	padding:0.25em;margin:0.25em;
	background:none;
	color:blue;
}
td form button.button:hover{
  background:none;
	color:steelblue;
}